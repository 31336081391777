import React from "react";
import { Routes, Route } from "react-router-dom";
import MainLayout from "./components/Layout/MainLayout";
import Login from "./components/Login/Login.js";
import Dashboard from "./components/Dashboard/Dashboard.js";
import ConversationHistory from '../src/components/ConversationHistory/ConversationHistory';
import Personalization from "./components/Personalization/Personalization.js";
import Share from "./components/Share/ShareComponent.js";
import EstimationForm from "./components/EstimationForm/EstimationForm.js";
import PersonalityTest from "./components/PersonalityTest/PersonalityTest.js";
import Assesment from "./components/Assesment/Assesment.js";


function App() {
  
  return (
    <Routes>
       <Route path="/" element={<MainLayout />} />
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/Onangwatgo-Dálach" element={<ConversationHistory />} />
      <Route path="/personalization" element={<Personalization />} />
      <Route path="/share/:session" element={<Share />} />
       <Route path="/estimation" element={<EstimationForm />} />
       <Route path="/personality-test" element={<PersonalityTest />} />
       <Route path="/assesment" element={<Assesment />} />
    </Routes>
  );
}

export default App;
