import React, { useEffect, useState } from 'react';
import './Assesment.css';
import edulogo from "../../img/pick/edu_advisor_logo.png";
import done_icon from "../../img/pick/done_icon.svg";
import ReactDOMServer from 'react-dom/server';
import genaratevideo from '../../videos/chat/genarating_animation_loop.mp4'
import knWizardLogo from '../../img/knwizard/kn-wizard-logo.png'
import knWizardbanner from '../../img/knwizard/main-banner.jpg'
import downArrow from '../../img/knwizard/downArrow.png'

const Assesment = () => {
  const [currentStep, setCurrentStep] = useState(1);
  // const chatSession = localStorage.getItem("chatSession");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [reportData, setReportData] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [chatSession, setChatSession] = useState('');

  // Function to generate a unique chat session ID
  useEffect(() => {
    const generateChatSessionId = () => {
      const uniqueId = `session-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
      return uniqueId;
    };

    setChatSession(generateChatSessionId());
  }, []); 
  
  const [answers, setAnswers] = useState({
    name: '',
    gender: '',
    age: '',
    heightFeet: '',
    heightInches: '',
    weight: '',
    sleepHours: 0,
    wakeUpFeelingEnergized: '',
    dietBalance: '',
    processedFoodFrequency: '',
    physicalActivityLevel: '',
    supportConnection: '',
    socialCircleSupport: '',
    emotionalBalance: '',
    healthyHabits: [],
    otherHealthyHabit: '',
    smokingTobacco: '',
    alcoholConsumption: '',
    healthChallenges: '',
    healthChallengesDescription: '',
    relationshipSatisfaction: '',
    financialStress: '',
    financialResources: '',
    stressLevel: 0,
    feelingOverwhelmed: '',
    copingStrategies: [],
    otherStrategy: '',
    facialsScan: '',
    additionalRemarks: '',
  });
  const [completedSteps, setCompletedSteps] = useState(0);
  const [activeDropdown, setActiveDropdown] = useState(null);

  // Load answers from local storage when the component mounts
  useEffect(() => {
    const savedAnswers = JSON.parse(localStorage.getItem('answers'));
    if (savedAnswers) {
      setAnswers(savedAnswers);
    }
  }, []);

  // Save answers to local storage whenever they change
  useEffect(() => {
    localStorage.setItem('answers', JSON.stringify(answers));
  }, [answers]);

  // Count completed steps based on the answers
  const countCompletedSteps = () => {
    let count = 0;
    if (answers.name) count++;
    if (answers.gender) count++;
    if (answers.age) count++;
    if (answers.heightFeet && answers.heightInches) count++;
    if (answers.weight) count++;
    if (answers.sleepHours > 0) count++;
    if (answers.wakeUpFeelingEnergized) count++;
    if (answers.dietBalance) count++;
    if (answers.processedFoodFrequency) count++;
    if (answers.physicalActivityLevel) count++;
    if (answers.supportConnection) count++;
    if (answers.socialCircleSupport) count++;
    if (answers.emotionalBalance) count++;
    if (answers.healthyHabits.length > 0) count++;
    if (answers.otherHealthyHabit) count++;
    if (answers.smokingTobacco) count++;
    if (answers.alcoholConsumption) count++;
    if (answers.healthChallenges) count++;
    if (answers.healthChallengesDescription) count++;
    if (answers.relationshipSatisfaction) count++;
    if (answers.financialStress) count++;
    if (answers.financialResources) count++;
    if (answers.stressLevel > 0) count++;
    if (answers.feelingOverwhelmed) count++;
    if (Array.isArray(answers.copingStrategies) && answers.copingStrategies.length > 0) count++;
    if (Array.isArray(answers.copingStrategies) && answers.copingStrategies.includes('Other') && answers.otherStrategy) count++;
    if (answers.facialsScan) count++;
    if (answers.additionalRemarks) count++;
    setCompletedSteps(count);
  };

  // Handler for moving to the next step
  const handleNext = () => {
    const showError = (message) => {
      const errorElement = document.createElement('div');
      errorElement.className = 'error-message';
      errorElement.textContent = message;
      document.body.appendChild(errorElement);
      setTimeout(() => {
        document.body.removeChild(errorElement);
      }, 3000); // Remove the message after 3 seconds
    };


    if (currentStep === 1 && !answers.name) {
      showError("Please enter your name to proceed.");
      return;
    }

    if (currentStep === 2) {
      if (!answers.gender) {
        showError("Please select your biological gender to proceed.");
        return;
      }
      if (!answers.age) {
        showError("Please enter your age to proceed.");
        return;
      }
      if (!answers.heightFeet || !answers.heightInches) {
        showError("Please enter your full height to proceed.");
        return;
      }
      if (!answers.weight) {
        showError("Please enter your weight to proceed.");
        return;
      }
    }

    if (currentStep === 3) {
      if (answers.sleepHours <= 0) {
        showError("Please specify your average sleep hours to proceed.");
        return;
      }
      if (!answers.wakeUpFeelingEnergized) {
        showError("Please select how often you wake up feeling energized to proceed.");
        return;
      }
      if (!answers.dietBalance) {
        showError("Please rate the balance of your diet to proceed.");
        return;
      }
      if (!answers.processedFoodFrequency) {
        showError("Please select how often you rely on processed foods to proceed.");
        return;
      }
      if (!answers.physicalActivityLevel) {
        showError("Please select your regular physical activity level to proceed.");
        return;
      }
    }

    if (currentStep === 5) {
      if (!answers.supportConnection) {
        showError("Please select how often you connect meaningfully with friends or family to proceed.");
        return;
      }

      if (!answers.socialCircleSupport) {
        showError("Please select how supported you feel by your social circle to proceed.");
        return;
      }
    }

    if (currentStep === 6) {
      if (!answers.emotionalBalance) {
        showError("Please select how often you feel emotionally balanced to proceed.");
        return false;
      }

      // Validate Healthy Habits
      if (!Array.isArray(answers.healthyHabits) || answers.healthyHabits.length === 0) {
        showError("Please select at least one healthy habit to proceed.");
        return false;
      }

      // Validate Other Healthy Habit
      if (answers.healthyHabits.includes('Other') && !answers.otherHealthyHabit) {
        showError("Please specify your other healthy habit.");
        return false;
      }
    }


    if (currentStep === 7) {
      if (!answers.smokingTobacco) {
        showError("Please specify your smoking or tobacco usage to proceed.");
        return;
      }
      if (!answers.alcoholConsumption) {
        showError("Please specify your alcohol consumption to proceed.");
        return;
      }
      if (!answers.healthChallenges) {
        showError("Please specify if you have any ongoing health challenges to proceed.");
        return;
      }
      if (!answers.relationshipSatisfaction) {
        showError("Please specify your satisfaction with your close relationships to proceed.");
        return;
      }
    }

    if (currentStep === 8) {
      if (!answers.financialStress) {
        showError("Please specify how often you feel stressed about your financial situation to proceed.");
        return;
      }
      if (!answers.financialResources) {
        showError("Please specify if you have the financial resources to comfortably meet your needs to proceed.");
        return;
      }

    }

    if (currentStep === 4) {
      // Validate Stress Level
      if (answers.stressLevel <= 0) {
        showError("Please specify your current stress level to proceed.");
        return;
      }

      // Validate Feeling Overwhelmed
      if (!answers.feelingOverwhelmed) {
        showError("Please select how often you feel overwhelmed or anxious to proceed.");
        return;
      }

      // Validate Coping Strategies
      if (!Array.isArray(answers.copingStrategies) || answers.copingStrategies.length === 0) {
        showError("Please select at least one coping strategy to proceed.");
        return;
      }

      // Validate Other Coping Strategy
      if (answers.copingStrategies.includes('Other') && !answers.otherStrategy) {
        showError("Please specify your other coping strategy.");
        return;
      }
    }


    if (currentStep === 9) {
      if (!answers.facialsScan) {
        showError("Please select whether you want to include a facial video scan to proceed.");
        return;
      }
      if (answers.additionalRemarks === undefined) {
        showError("Please provide any additional remarks or leave the field blank to proceed.");
        return;
      }
    }


    setCurrentStep(currentStep + 1);
    countCompletedSteps();
  };

  // Handler for moving to the previous step
  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
    countCompletedSteps(); // Ensure progress bar updates when going back
  };

  // Handler for updating text inputs
  const handleInputChange = (step, value) => {
    setAnswers(prevAnswers => {
      const newAnswers = {
        ...prevAnswers,
        [step]: value,
      };
      countCompletedSteps(); // Update progress bar on change
      return newAnswers;
    });
    setActiveDropdown(null);
  };


  // Toggle full-screen popup visibility

  // Handler for updating radio inputs
  const handleRadioChange = (step, value) => {
    // Update the state with the new value and also update the progress bar
    setAnswers(prevAnswers => {
      const updatedAnswers = {
        ...prevAnswers,
        [step]: value,
      };
      countCompletedSteps(); // Update progress bar on change
      return updatedAnswers;

    });

    const updateCopingStrategies = (value) => {
      setAnswers(prevAnswers => {
        const newArray = [...prevAnswers.copingStrategies]; // Copy the existing array
        if (newArray.includes(value)) {
          // Remove the value if it's already included
          return { ...prevAnswers, copingStrategies: newArray.filter(item => item !== value) };
        } else {
          // Add the value if it's not included
          return { ...prevAnswers, copingStrategies: [...newArray, value] };
        }
      });
    };



    // Manage label classes
    const labels = document.querySelectorAll('.radio-label');
    labels.forEach(label => label.classList.remove('checked'));
    const checkedLabel = document.querySelector(`input[name="${step}"][value="${value}"]`).closest('.radio-label');
    if (checkedLabel) {
      checkedLabel.classList.add('checked');
    }
  };

  const handleTextChange = (field, value) => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [field]: value
    }));
  };

  const updateCopingStrategies = (value) => {
    setAnswers(prevAnswers => {
      const currentStrategies = Array.isArray(prevAnswers.copingStrategies) ? prevAnswers.copingStrategies : [];
      const newArray = [...currentStrategies];
      if (newArray.includes(value)) {
        return { ...prevAnswers, copingStrategies: newArray.filter(item => item !== value) };
      } else {
        return { ...prevAnswers, copingStrategies: [...newArray, value] };
      }
    });
  };



  // Handler for updating checkbox inputs
  const handleCheckboxChange = (field, value) => {
    setAnswers(prevAnswers => {
      if (field === 'healthyHabits') {
        // Ensure healthyHabits is an array
        const updatedHabits = Array.isArray(prevAnswers.healthyHabits)
          ? prevAnswers.healthyHabits.includes(value)
            ? prevAnswers.healthyHabits.filter(habit => habit !== value) // Remove if already included
            : [...prevAnswers.healthyHabits, value] // Add if not included
          : [value]; // Initialize if undefined

        return {
          ...prevAnswers,
          [field]: updatedHabits
        };
      }

      // Handle other fields if necessary
      return {
        ...prevAnswers,
        [field]: value
      };
    });
  };


  const handleCheckboxChange2 = (trait2) => {
    setAnswers(prevAnswers2 => {
      const newTraits2 = prevAnswers2.traits2.includes(trait2)
        ? prevAnswers2.traits2.filter(item => item !== trait2)
        : [...prevAnswers2.traits2, trait2];
      const newAnswers2 = {
        ...prevAnswers2,
        traits2: newTraits2,
      };
      countCompletedSteps(); // Update progress bar on change
      return newAnswers2;
    });
  };
  // Handler for updating rating inputs
  const handleRatingChange = (statement, value) => {
    setAnswers(prevAnswers => {
      const newAnswers = {
        ...prevAnswers,
        ratings: { ...prevAnswers.ratings, [statement]: value },
      };
      countCompletedSteps(); // Update progress bar on change
      return newAnswers;
    });
  };

  // Handler for finishing the wizard
  const handleFinish = async (event) => {
    event.preventDefault();
    // Prepare questions and answers
    const ratingScale = {
      1: "Strongly Disagree",
      2: "Disagree",
      3: "Neutral",
      4: "Agree",
      5: "Strongly Agree"
    };
    const heightInInches = (answers.heightFeet * 12) + answers.heightInches;
    const bmi = (answers.weight / (heightInInches ** 2)) * 703;
    
    // Prepare questions and answers
    const content = `
  1. What's your name? ${answers.name}
  2. What’s your biological gender? ${answers.gender}
  2. How old are you? ${answers.age}
  3. What is BMI value? ${bmi}
  4. On average, how many hours do you sleep each night? ${answers.sleepHours}
  5. Do you wake up feeling energized? ${answers.wakeUpFeelingEnergized}
  6. How balanced do you feel your diet is?${answers.dietBalance}
  7. How often do you rely on processed foods or fast food?  ${answers.processedFoodFrequency}
  8. What’s your regular physical activity level? ${answers.physicalActivityLevel}
  9. On a scale of 1-10, where would you place your current stress level? ${answers.stressLevel}
  10. How often do you feel overwhelmed or anxious? ${answers.feelingOverwhelmed}
  11. Which coping strategies work best for you? ${answers.copingStrategies.join(', ')}
  12. Which coping other strategies work best for you? ${answers.otherStrategy}
  12. How often do you connect meaningfully with friends or family? ${answers.supportConnection}
  13. Do you feel supported by your social circle?  ${answers.socialCircleSupport}
  14. How often do you feel emotionally balanced and stable? ${answers.emotionalBalance}
  15. What healthy habits help you maintain balance? ${answers.healthyHabits.join(', ')}
  16. What other healthy habits help you maintain balance?${answers.otherHealthyHabit}
  17. Do you smoke or use tobacco products? ${answers.smokingTobacco}
  18. How often do you drink alcohol?   ${answers.alcoholConsumption}
  19. Do you have any ongoing health challenges that impact your wellness journey? ${answers.healthChallenges}
  20. Do you have any ongoing health challenges that impact your wellness journey(discription)? ${answers.healthChallengesDescription}
  21. How satisfied are you with the intimacy and connection in your close relationships? ${answers.relationshipSatisfaction}
  22. How often do you feel stressed about your financial situation? ${answers.financialStress}
  23. Do you feel you have the financial resources to comfortably meet your needs? ${answers.financialResources}
  24. Would you like to include a facial video scan to enhance your results? ${answers.facialsScan}
  25. Any additional remarks you’d like to share? ${answers.additionalRemarks}
  `;

    const payload = {
      conversations: [
        {
          role: "user",
          content: content,
        }
      ],
      chatSession: chatSession,
    };

    setIsPopupVisible(true);


    try {
      const response = await fetch('https://dashboard.knightingale.ai/ai/run/75ea1f4e-8d43-495d-b603-649cf559145a', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      console.log('ss' + response.completion)
      if (response.ok) {

        const data = await response.json(); // Parse the JSON from the response
        console.log(data.completion);
        setReportData(data.completion);

        // setAnswers({
        //   name: '',
        //   quickPick: '',
        //   learningStyle: '',
        //   decisionMaking: '',
        //   lifeApproach: '',
        //   ratings: {
        //     statement1: '',
        //     statement2: '',
        //     statement3: '',
        //     statement4: '',
        //     statement5: '',
        //     statement6: '',
        //     statement7: '',
        //     statement8: '',
        //   },
        //   sports: [],
        //   traits: [],
        //   traits2: [],
        //   personalityDescription: '',
        //   personalStrenth: '',
        //   developarealStrenth: '',
        //   feelMostEnlightened: '',
        // });http://assessment.knightingale.ai/

        setIsSubmitted(true); // Show the submitted message

      } else {
        console.error('Error submitting the form:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      // setIsPopupVisible(false); // Hide the popup
    }


    console.log(content);

  };


  // Render progress bar
  const renderProgressBar = () => (
    <div className="progress-bar-container">
      <progress value={completedSteps} max="22" className="progress-bar" />
      <p className="progress-text">

      </p>
    </div>
  );



  const renderStep21to25 = () => (
    <div className="wizard-section">

      <div className='assesment-top'>
        <p className='steptype'>Step 2:</p>
        <h2 >Lifestyle Snapshot</h2>
        <p className='stepDetails'>How do your daily habits measure up? Let’s explore and find out!</p>
      </div>
      <div className="button-group">
        {currentStep < 10 && 1 < currentStep && renderProgressBar()}
      </div>
      <div className="wizard-question">
        <label htmlFor="sleepHours" className="knwizard-question">On average, how many hours do you sleep each night?</label>
        <input
          type="range"
          id="sleepHours"
          min="1"
          max="12"
          value={answers.sleepHours}
          onChange={(e) => handleInputChange('sleepHours', e.target.value)}
        />
        <p>Selected: {answers.sleepHours} hours</p>
      </div>
      <div className="wizard-question">
        <p className="knwizard-question ">Do you wake up feeling energized?</p>
        {['Almost every day', 'Most days', 'Occasionally', 'Rarely'].map(option => (
          <label key={option} className={`radio-label ${answers.wakeUpFeelingEnergized === option ? 'selectedActive' : ''}`}>
            <input
              type="radio"
              name="wakeUpFeelingEnergized"
              value={option}
              checked={answers.wakeUpFeelingEnergized === option}
              onChange={() => handleRadioChange('wakeUpFeelingEnergized', option)}
            />
            <span className='radio-text'>{option}</span>
          </label>
        ))}
      </div>
      <div className="wizard-question">
        <p className="knwizard-question">How balanced do you feel your diet is?</p>
        {['Excellent – I’m pretty mindful', 'Fairly balanced – I try to make healthy choices', 'Needs work – I struggle with consistency', 'Poor – It’s often convenience over quality'].map(option => (
          <label key={option} className={`radio-label ${answers.dietBalance === option ? 'selectedActive' : ''}`}>
            <input
              type="radio"
              name="dietBalance"
              value={option}
              checked={answers.dietBalance === option}
              onChange={() => handleRadioChange('dietBalance', option)}
            />
            <span className='radio-text'>{option}</span>
          </label>
        ))}
      </div>
      <div className="wizard-question">
        <p className="knwizard-question">How often do you rely on processed foods or fast food?</p>
        {['Rarely – Only as a last resort', 'Occasionally – I do treat myself', 'Frequently – It’s part of my routine', 'Most meals – I know I could do better'].map(option => (
          <label key={option} className={`radio-label ${answers.processedFoodFrequency === option ? 'selectedActive' : ''}`}>
            <input
              type="radio"
              name="processedFoodFrequency"
              value={option}
              checked={answers.processedFoodFrequency === option}
              onChange={() => handleRadioChange('processedFoodFrequency', option)}
            />
            <span className='radio-text'>{option}</span>
          </label>
        ))}
      </div>
      <div className="wizard-question">
        <p className="knwizard-question">What’s your regular physical activity level?</p>
        {['Active daily (e.g., workouts, sports, vigorous activities)', 'Moderately active (e.g., regular walks, light exercise)', 'Sporadic activity (e.g., occasional workouts)', 'Mostly sedentary (e.g., desk job, limited movement)'].map(option => (
          <label key={option} className={`radio-label ${answers.physicalActivityLevel === option ? 'selectedActive' : ''}`}>
            <input
              type="radio"
              name="physicalActivityLevel"
              value={option}
              checked={answers.physicalActivityLevel === option}
              onChange={() => handleRadioChange('physicalActivityLevel', option)}
            />
            <span className='radio-text'>{option}</span>
          </label>
        ))}
      </div>
    </div>
  );


  const renderSocialSupportStep = () => (
    <div className="wizard-section">
      <div className='assesment-top'>
        <p className='steptype'>Step 4:</p>
        <h2 >Social Support & Connection</h2>
        <p className='stepDetails'>Healthy relationships are vital for overall wellness.</p>
      </div>
      <div className="wizard-question">
        <p>How often do you connect meaningfully with friends or family?</p>
        {['Several times a week', 'Once a week', 'A couple of times a month', 'Rarely/Never'].map(option => (
          <label key={option} className={`radio-label ${answers.supportConnection === option ? 'selectedActive' : ''}`}>
            <input
              type="radio"
              name="supportConnection"
              value={option}
              onChange={() => handleRadioChange('supportConnection', option)}
              checked={answers.supportConnection === option}
            />
            <span className='radio-text'>{option}</span>
          </label>
        ))}
      </div>
      <div className="wizard-question">
        <p>Do you feel supported by your social circle?</p>
        {['Always', 'Usually', 'Sometimes', 'Hardly ever'].map(option => (
          <label key={option} className={`radio-label ${answers.socialCircleSupport === option ? 'selectedActive' : ''}`}>
            <input
              type="radio"
              name="socialCircleSupport"
              value={option}
              onChange={() => handleRadioChange('socialCircleSupport', option)}
              checked={answers.socialCircleSupport === option}
            />
            <span className='radio-text'>{option}</span>
          </label>
        ))}
      </div>
      <div className="interactive-reminder">
        <p>Interactive Reminder: “Remember, staying connected is key! Consider scheduling time with a friend or loved one this week.”</p>
      </div>
    </div>
  );




  const handleClose = () => {
    setIsSubmitted(true);
    window.location.href = '/'
  };


  const handlePrint = () => {
    const printWindow = window.open('', '', 'height=600,width=800');

    // Ensure the document structure is properly formatted
    printWindow.document.write(`<html><head><title>Print</title>
       <style>
          body {
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            margin: 0;
            padding: 20px;
          }
          .report-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
          }
          .report-left-side {
            text-align: left;
          }
          .report-right-side {
            text-align: right;
          }
          .report-logo {
            height: 50px;
          }
          .responseText {
            overflow-x: auto;
            white-space: pre-wrap;
            font-weight: normal;
            font-family: 'Roboto, sans-serif';
            font-size: 20px;
          }
          footer {
            margin-top: 20px;
            border-top: 1px solid #ddd;
            padding-top: 10px;
            text-align: center;
            font-size: 12px;
            color: #777;
          }
        </style>
      </head><body>`);
    printWindow.document.write('<div>');
    printWindow.document.write(`
      <div style="text-align: center; display: flex; align-items: center; justify-content: center;">
      </div>
    `);
    printWindow.document.write(`${renderResponseToHtml()}`);  // Ensure renderResponse() returns a valid HTML snippet
    printWindow.document.write(`
      <footer style="margin-top: 20px; border-top: 1px solid #ddd; padding-top: 10px;">
      </footer>
    `);
    printWindow.document.write('</div>');
    printWindow.document.write('</body></html>');

    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };


  const renderResponseToHtml = () => {
    return ReactDOMServer.renderToStaticMarkup(renderResponse());
  };

  const renderResponse = () => {
    if (reportData) {
      // Replace double asterisks with <strong> tags for bold text
      const formattedReportData = reportData
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')  // Replace double asterisks with <strong> tags
        .replace(/##(.*?)/g, '<strong>$1</strong>');  // Replace text between double hash with <strong> tags


      const getCurrentDate = () => {
        const today = new Date();
        return today.toLocaleDateString(); // Format as needed, e.g., "MM/DD/YYYY"
      };
      return (
        <div>
          {/* Header Section */}
          <header className='report-header'>
            <div className="report-left-side">
              <h1>Personality Test</h1>
              <p>Test Date : {getCurrentDate()} </p>
              <p>Test ID: {chatSession}</p>
            </div>
            <div className="report-right-side">
              <img src={edulogo} alt="Logo" className='report-logo' />
            </div>
          </header>

          {/* Main Content */}
          <pre
            className="responseText"
            style={{ overflowX: 'auto', whiteSpace: 'pre-wrap', fontWeight: 'normal', fontFamily: 'Roboto, sans-serif', fontSize: '20px' }}
            dangerouslySetInnerHTML={{ __html: formattedReportData }}
          ></pre>
        </div>
      );
    }
    return null;
  };



  // Open the modal for the selected dropdown
  const openDropdown = (dropdown) => {
    setActiveDropdown(dropdown);
  };

  // Close the modal
  const closeDropdown = () => {
    setActiveDropdown(null);
  };

  // Render dropdown options based on the field
  const renderDropdownOptions = (field) => {
    switch (field) {
      case 'gender':
        return (
          <>
            <div onClick={() => handleInputChange('gender', 'Male')}>Male</div>
            <div onClick={() => handleInputChange('gender', 'Female')}>Female</div>
            <div onClick={() => handleInputChange('gender', 'Prefer not to say')}>Prefer not to say</div>
          </>
        );
      case 'age':
        return (
          <>
            {Array.from({ length: 100 }, (_, i) => i + 1).map(age => (
              <div key={age} onClick={() => handleInputChange('age', age)}>{age}</div>
            ))}
          </>
        );
      case 'heightFeet':
        return (
          <>
            {[...Array(8)].map((_, i) => (
              <div key={i} onClick={() => handleInputChange('heightFeet', i)}>{i} feet</div>
            ))}
          </>
        );
      case 'heightInches':
        return (
          <>
            {[...Array(12)].map((_, i) => (
              <div key={i} onClick={() => handleInputChange('heightInches', i)}>{i} inches</div>
            ))}
          </>
        );
      case 'weight':
        return (
          <>
            {Array.from({ length: 300 }, (_, i) => i + 1).map(weight => (
              <div key={weight} onClick={() => handleInputChange('weight', weight)}>{weight} lbs</div>
            ))}
          </>
        );
      default:
        return null;
    }
  };


  const renderEmotionalBalanceStep = () => (
    <div className="wizard-section">
      <div className='assesment-top'>
        <p className='steptype'>Step 5</p>
        <h2>Your Emotional Balance</h2>
        <p className='stepDetails'>Evaluate your emotional well-being and how you handle ups and downs.</p>
      </div>
      <div className="button-group">
        {currentStep < 10 && 1 < currentStep && renderProgressBar()}
      </div>
      <div className="wizard-question">
        <p className='knwizard-question'>How often do you feel emotionally balanced and stable?</p>
        {['Almost every day', 'Most days', 'Some days, but I have off days', 'Rarely – I struggle often'].map(option => (
          <label key={option} className={`radio-label ${answers.emotionalBalance === option ? 'selectedActive' : ''}`}>
            <input
              type="radio"
              name="emotionalBalance"
              value={option}
              onChange={() => handleRadioChange('emotionalBalance', option)}
              checked={answers.emotionalBalance === option}
            />
            <span className='radio-text'>{option}</span>
          </label>
        ))}
      </div>
      <div className="wizard-question">
        <p className='knwizard-question'>What healthy habits help you maintain balance? (Select all that apply)</p>
        {[
          'Physical activity',
          'Relaxation techniques',
          'Talking things out',
          'Expressive activities (art, music, journaling)',
          'Other'
        ].map(option => (
          <label
            key={option}
            className={`checkbox-label ${Array.isArray(answers.healthyHabits) && answers.healthyHabits.includes(option) ? 'selectedActive' : ''}`}
          >
            <input
              type="checkbox"
              name="healthyHabits"
              value={option}
              onChange={() => handleCheckboxChange('healthyHabits', option)}
              checked={Array.isArray(answers.healthyHabits) && answers.healthyHabits.includes(option)}
            />
            {option}
            {option === 'Other' && (
              <input
                type="text"
                name="otherHealthyHabit"
                value={answers.otherHealthyHabit || ''}
                onChange={(e) => handleTextChange('otherHealthyHabit', e.target.value)}
              />
            )}
          </label>
        ))}
      </div>
      <div className="motivational-popup">
        <p>Motivational Pop-Up: “Great job taking steps toward emotional wellness. Keep it up!”</p>
      </div>
    </div>
  );



  // Render the health, lifestyle choices, and relationship well-being step
  const renderHealthLifestyleStep = () => (
    <div className="wizard-section">
      <div className='assesment-top'>
        <p className='steptype'>Step 6:</p>
        <h2>Health, Lifestyle Choices & Relationship Well-being</h2>
        <p className='stepDetails'>Understanding your lifestyle choices helps us create a more personalized plan.</p>
      </div>
      <div className="button-group">
        {currentStep < 10 && 1 < currentStep && renderProgressBar()}
      </div>
      <div>
        <p className='knwizard-question'>Do you smoke or use tobacco products?</p>
        {['No, never', 'Occasionally', 'Regularly'].map(option => (
          <label key={option} className={`radio-label ${answers.smokingTobacco === option ? 'selectedActive' : ''}`}>
            <input
              type="radio"
              name="smokingTobacco"
              value={option}
              onChange={() => handleRadioChange('smokingTobacco', option)}
              checked={answers.smokingTobacco === option}
            />
            <span className='radio-text'>{option}</span>
          </label>
        ))}

        <p className='knwizard-question'>How often do you drink alcohol?</p>
        {['Rarely/Never', '1-2 times per week', '3-4 times per week', 'Most days'].map(option => (
          <label key={option} className={`radio-label ${answers.alcoholConsumption === option ? 'selectedActive' : ''}`}>
            <input
              type="radio"
              name="alcoholConsumption"
              value={option}
              onChange={() => handleRadioChange('alcoholConsumption', option)}
              checked={answers.alcoholConsumption === option}
            />
            <span className='radio-text'>{option}</span>
          </label>
        ))}

        <p className='knwizard-question'>Do you have any ongoing health challenges that impact your wellness journey?</p>
        {['Yes, I’m managing some conditions', 'No, I feel generally healthy'].map(option => (
          <label key={option} className={`radio-label ${answers.healthChallenges === option ? 'selectedActive' : ''}`}>
            <input
              type="radio"
              name="healthChallenges"
              value={option}
              onChange={() => handleRadioChange('healthChallenges', option)}
              checked={answers.healthChallenges === option}
            />
            <span className='radio-text'>{option}</span>
          </label>
        ))}
        <label className="optional-label">
          Optional:
          <textarea
            name="healthChallengesDescription"
            value={answers.healthChallengesDescription || ''}
            onChange={(e) => handleTextChange('healthChallengesDescription', e.target.value)}
            placeholder="Describe how these challenges affect your habits or goals"
          />
        </label>

        <p className='knwizard-question'>How satisfied are you with the intimacy and connection in your close relationships?</p>
        {['Very satisfied', 'Mostly satisfied', 'Neutral', 'Dissatisfied'].map(option => (
          <label key={option} className={`radio-label ${answers.relationshipSatisfaction === option ? 'selectedActive' : ''}`}>
            <input
              type="radio"
              name="relationshipSatisfaction"
              value={option}
              onChange={() => handleRadioChange('relationshipSatisfaction', option)}
              checked={answers.relationshipSatisfaction === option}
            />
            <span className='radio-text'>{option}</span>
          </label>
        ))}
      </div>
    </div>
  );


  // Render the financial wellness step
  const renderFinancialWellnessStep = () => (
    <div className="wizard-section">
      <div className='assesment-top'>
        <p className='steptype'>Step 7:</p>
        <h2>Financial Wellness Check</h2>
        <p className='stepDetails'>Understanding how finances impact your wellness helps us deliver well-rounded advice.</p>
      </div>
      <div className="button-group">
        {currentStep < 10 && 1 < currentStep && renderProgressBar()}
      </div>
      <div>
        <p className='knwizard-question'>How often do you feel stressed about your financial situation?</p>
        {['Rarely/Never', 'Occasionally', 'Often', 'Constantly'].map(option => (
          <label key={option} className={`radio-label ${answers.financialStress === option ? 'selectedActive' : ''}`}>
            <input
              type="radio"
              name="financialStress"
              value={option}
              onChange={() => handleRadioChange('financialStress', option)}
              checked={answers.financialStress === option}
            />
            <span className='radio-text'>{option}</span>
          </label>
        ))}

        <p className='knwizard-question'>Do you feel you have the financial resources to comfortably meet your needs?</p>
        {['Yes, fully', 'Mostly', 'Sometimes', 'No'].map(option => (
          <label key={option} className={`radio-label ${answers.financialResources === option ? 'selectedActive' : ''}`}>
            <input
              type="radio"
              name="financialResources"
              value={option}
              onChange={() => handleRadioChange('financialResources', option)}
              checked={answers.financialResources === option}
            />
            <span className='radio-text'>{option}</span>
          </label>
        ))}

        <div className="empathetic-tip">
          <p>Empathetic Tip: “Financial stress is tough, but small steps like budgeting can make a big difference.”</p>
        </div>
      </div>
    </div>
  );



  const renderStressAndResilienceCheck = () => (
    <div className="wizard-section">
      <div className='assesment-top'>
        <p className='steptype'>Step 3:</p>
        <h2>Step 3: Stress & Resilience Check</h2>
        <p className='stepDetails'>Let’s get a sense of how stress impacts your life and how you manage it.</p>
      </div>
      <div className="button-group">
        {currentStep < 10 && 1 < currentStep && renderProgressBar()}
      </div>
      <div className="wizard-question">
        <label htmlFor="stressLevel" className='knwizard-question'>On a scale of 1-10, where would you place your current stress level?</label>
        <input
          type="range"
          id="stressLevel"
          min="1"
          max="10"
          value={answers.stressLevel}
          onChange={(e) => handleInputChange('stressLevel', e.target.value)}
        />
        <p>Selected: {answers.stressLevel} (1 = Very Low, 10 = Very High)</p>
      </div>

      <div className="wizard-question">
        <p className='knwizard-question'>How often do you feel overwhelmed or anxious?</p>
        {['Rarely', 'Occasionally', 'Often', 'Almost always'].map(option => (
          <label key={option} className={`radio-label ${answers.feelingOverwhelmed === option ? 'selectedActive' : ''}`}>
            <input
              type="radio"
              name="feelingOverwhelmed"
              value={option}
              checked={answers.feelingOverwhelmed === option}
              onChange={() => handleRadioChange('feelingOverwhelmed', option)}
            />
            <span className='radio-text'>{option}</span>
          </label>
        ))}
      </div>

      <div className="wizard-question">
        <p className='knwizard-question'>Which coping strategies work best for you? (Select all that apply)</p>
        {['Mindfulness or meditation', 'Regular exercise', 'Talking it out with someone', 'Creative outlets (art, writing, etc.)', 'Other'].map(option => (
          <label
            key={option}
            className={`checkbox-label ${Array.isArray(answers.copingStrategies) && answers.copingStrategies.includes(option) ? 'selectedActive' : ''}`}
          >
            <input
              type="checkbox"
              name="copingStrategies"
              value={option}
              checked={Array.isArray(answers.copingStrategies) && answers.copingStrategies.includes(option)}
              onChange={() => updateCopingStrategies(option)}
            />
            <span className='checkbox-text'>{option}</span>
          </label>
        ))}
        {Array.isArray(answers.copingStrategies) && answers.copingStrategies.includes('Other') && (
          <input
            type="text"
            placeholder="Please specify"
            value={answers.otherStrategy || ''}
            onChange={(e) => handleInputChange('otherStrategy', e.target.value)}
          />
        )}
      </div>


      <div className="instant-tip">
        <p>Instant Micro-Tip: “Feeling stressed? Here’s a quick breathing exercise you can try right now.”</p>
      </div>
    </div>
  );


  const renderPersonalizedWellnessPlanStep = () => (
    <div className="wizard-section">
      <div className='assesment-top'>
        <p className='steptype'>Step 8:</p>
        <h2>Get Your Personalized Wellness Plan</h2>
        <p className='stepDetails'>You’re almost there! For even deeper insights, choose an optional scan or leave a note.</p>
      </div>
      <div className="button-group">
        {currentStep < 10 && 1 < currentStep && renderProgressBar()}
      </div>
      <div className="wizard-question">
        <p class="knwizard-question">Would you like to include a facial video scan to enhance your results?</p>
        <label className={`radio-label ${answers.facialsScan === 'Yes' ? 'selectedActive' : ''}`}>
          <input
            type="radio"
            name="facialsScan"
            value="Yes"
            checked={answers.facialsScan === 'Yes'}
            onChange={() => handleRadioChange('facialsScan', 'Yes')}
          />
          <span className='radio-text'>Yes, analyze my facial expressions for more detailed insights.</span>
        </label>
        <label className={`radio-label ${answers.facialsScan === 'No' ? 'selectedActive' : ''}`}>
          <input
            type="radio"
            name="facialsScan"
            value="No"
            checked={answers.facialsScan === 'No'}
            onChange={() => handleRadioChange('facialsScan', 'No')}
          />
          <span className='radio-text'>No, I’ll skip the video scan.</span>
        </label>
      </div>
      <div className="wizard-question">
        <p class="knwizard-question">Optional: Any additional remarks you’d like to share?</p>
        <textarea
          id="additionalRemarks"
          placeholder="Type anything you think might be useful in your wellness analysis (e.g., specific goals, concerns, or habits)."
          value={answers.additionalRemarks || ''}
          onChange={(e) => handleInputChange('additionalRemarks', e.target.value)}
        />
      </div>
    </div>
  );

  return (
    <div className='wizard'>
      <div className='wizard-wrapper'>
        <div className='kn-wizard-logo'>
          <img src={knWizardLogo} alt='knightingale logo' />
        </div>
        <div className="wizard-container wizard-container-start">

          <div className='wizard-header-wrapper'>
            <div className='wizard-header-wrapper'>
              <div className='wizard-header-wrapper'>

              </div>
              <div className='wizard-header-wrapper'>

              </div>
            </div>
          </div>
          {/* {currentStep < 10 && 1 < currentStep &&
          <p className="progress-text">
            {` ${completedSteps} `}of 24 questions answered
          </p>
        } */}

          {currentStep === 1 && (
            <div className="wizard-section">
              <div className='knwizard-banner'>
                <img src={knWizardbanner} alt='knightingale logo' />
              </div>
              <div className="kngreeting">
                Your Personalized Wellness Journey Begins Here
              </div>
              <div className='personal-description'>
                <p className='knwizard-question'>What’s your name or nickname?</p>
                <input
                  type="text"
                  placeholder="Enter your first name"
                  value={answers.name}
                  onChange={(e) => handleInputChange('name', e.target.value)}
                  className="input-field knwizard-input"
                />
              </div>
            </div>
          )}


          {currentStep === 2 && (
            // <div className="wizard-section">
            //   <div className='assesment-top'>
            //     <h2>Step 1: Your Personal Profile</h2>
            //     <p>Let’s start by getting to know you so we can tailor everything to fit your unique needs.</p>
            //   </div>
            //   <div className="wizard-assement-question wizard-assement-question-step1">
            //     <label>01. What’s your biological gender?</label>
            //     <div>
            //       <label className="radio-label">
            //         <input type="radio" name="gender" value="Male" onChange={(e) => handleRadioChange('gender', e.target.value)} /> Male
            //       </label>
            //       <label className="radio-label">
            //         <input type="radio" name="gender" value="Female" onChange={(e) => handleRadioChange('gender', e.target.value)} /> Female
            //       </label>
            //       <label className="radio-label">
            //         <input type="radio" name="gender" value="Prefer not to say" onChange={(e) => handleRadioChange('gender', e.target.value)} /> Prefer not to say
            //       </label>
            //     </div>
            //   </div>
            //   <div className="wizard-assement-question wizard-assement-question-step1">
            //     <label>02. How old are you?</label>
            //     <input type="number" value={answers.age} onChange={(e) => handleInputChange('age', e.target.value)} /> years
            //   </div>
            //   <div className="wizard-assement-question wizard-assement-question-step1">
            //     <label>03. How tall are you?</label>
            //     <input type="number" value={answers.heightFeet} onChange={(e) => handleInputChange('heightFeet', e.target.value)} /> feet
            //     <input type="number" value={answers.heightInches} onChange={(e) => handleInputChange('heightInches', e.target.value)} /> inches
            //   </div>
            //   <div className="wizard-assement-question wizard-assement-question-step1">
            //     <label>04. How much do you weigh?</label>
            //     <input type="number" value={answers.weight} onChange={(e) => handleInputChange('weight', e.target.value)} /> pounds
            //   </div>
            // </div>
            <div className="wizard-section">
              <div className='assesment-top'>
                <p className='steptype'>Step 1:</p>
                <h2>Your Personal Profile</h2>
                <p className='stepDetails'>Let’s start by getting to know you so we can tailor everything to fit your unique needs.</p>
              </div>
              <div className="button-group">
                {currentStep < 10 && 1 < currentStep && renderProgressBar()}
              </div>
              {/* Gender Section */}
              <div className="wizard-assement-question wizard-assement-question-step1">
                <label className="knwizard-question"> What’s your biological gender?</label>
                <div className="dropdown" onClick={() => openDropdown('gender')}>
                  {answers.gender || "Select Gender"} <img src={downArrow} alt='down arrow' />
                </div>

              </div>

              {/* Age Section */}
              <div className="wizard-assement-question wizard-assement-question-step1">
                <label className="knwizard-question">02. How old are you?</label>
                <div className="dropdown" onClick={() => openDropdown('age')}>
                  {answers.age || "Select Age"} <img src={downArrow} alt='down arrow' />
                </div>
              </div>

              {/* Height Section */}
              <div className="wizard-assement-question wizard-assement-question-step1">
                <label className="knwizard-question">03. How tall are you?</label>
                <div className='dropdown-height'>
                  <div className="dropdown" onClick={() => openDropdown('heightFeet')}>
                    {answers.heightFeet || "Select Feet"}<img src={downArrow} alt='down arrow' />
                  </div>
                  <div className="dropdown" onClick={() => openDropdown('heightInches')}>
                    {answers.heightInches || "Select Inches"}<img src={downArrow} alt='down arrow' />
                  </div>
                </div>
              </div>

              {/* Weight Section */}
              <div className="wizard-assement-question wizard-assement-question-step1">
                <label className="knwizard-question">04. How much do you weigh?</label>
                <div className="dropdown" onClick={() => openDropdown('weight')}>
                  {answers.weight || "Select Weight"}<img src={downArrow} alt='down arrow' />
                </div>
              </div>

              <div class=" instant-tip-step1">
                <p> <span>Great!</span>You’re off to a fantastic start. Next, let’s dive into your lifestyle.</p>
              </div>
              {/* Full-screen Popup for Dropdown */}
              {activeDropdown && (
                <div className="fullscreen-popup">
                  <div className="popup-content">
                    <button className="close-button" onClick={closeDropdown}>Close</button>
                    {renderDropdownOptions(activeDropdown)}
                  </div>
                </div>
              )}
            </div>

          )}

          {currentStep === 3 && renderStep21to25()}

          {currentStep === 4 && renderStressAndResilienceCheck()}

          {currentStep === 5 && renderSocialSupportStep()}

          {currentStep === 6 && renderEmotionalBalanceStep()}

          {currentStep === 7 && renderHealthLifestyleStep()}

          {currentStep === 8 && renderFinancialWellnessStep()}

          {currentStep === 9 && renderPersonalizedWellnessPlanStep()}


          <div className="button-group">
            <div className='prev-next-container'>
              {currentStep > 1 && currentStep < 10 && (<div><button onClick={handlePrevious} className="button previous-button">Previous</button>
                <button onClick={handleNext} className="button next-button">Next</button></div>)}
              {currentStep === 1 && (<button onClick={handleNext} className="button next-start-button ">Lets Get Started</button>)}
            </div>
            {currentStep === 10 && (
              <div className="wizard-section">
                <div className="wizard-section-container">
                  <img className='finish-icon' src={done_icon} alt='done icon' />
                  <h3 className="finish-section-title"> Thank you for submitting, {answers.name}</h3>
                  <p className="completion-text"> Final Encouragement: “You’re amazing for taking this step toward better wellness! Let’s explore your results and start making progress.”</p>
                  <button onClick={handleFinish} className="button finish-button">View Report</button>
                </div>
              </div>
            )}
          </div>
          {isPopupVisible && (
            <div className='overlay'>
              {isSubmitted ? (
                <div className='successMessage'>

                  <div className='successText'>Thank you! Your form has been submitted successfully.</div>
                  <div className='report-container-print'>

                    {renderResponse()}
                  </div>
                  <div className='printButtons'>
                    <button className='okButton' onClick={handleClose}>Home</button>
                    <button className='okButton' onClick={handlePrint}>Print Report</button>
                  </div>

                </div>
              ) : (
                <div className='popupContent'>
                  {/* <div className='circle-loader'></div> */}
                  <div>
                    <video
                      className={`genarateVideo`}
                      style={{

                      }}
                      playsInline
                      autoPlay
                      loop
                      muted
                      src={genaratevideo}
                    />
                  </div>
                  <div className='popupTitle'>Generating Report...</div>
                  <div className='popupText'>Your Report is being Genarated. <br />Please wait...</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Assesment;






















