import React, { useState } from 'react';
import './EstimationForm.css';
import { useShareWindow } from "../../context/ShareWindowContext.js";
import aspi_desktop_idle from "../../videos/chat/aspirations_edu_advisor_idle.mp4";
import aspi_mobile_idle from "../../videos/chat/aspirations_edu_advisor_mobile_idle.mp4";



function EstimationForm() {
   
    const chatSession = localStorage.getItem("chatSession");
    const [answers, setAnswers] = useState({
        Q12: '',
        Q13: '',
        Q1: '',
        Q2: '',
        Q3: '',
        Q4: '',
        Q5: '',
        Q6: '',
        Q7: '',
        Q8: '',
        Q9: '',
        Q10: '',
        Q11: '',
    });
    // setSubmitSession(true);

    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setAnswers({ ...answers, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!answers.Q1 || !answers.Q2) {
            alert("Please fill in the mandatory fields: Email and Phone Number.");
            return;
        }

        const content = `
1. Name ${answers.Q12}
2. Company Name ${answers.Q13}
3. Let's Get to Know Each Other(Email) ${answers.Q1}
4. Let's Get to Know Each Other(Phone Number) ${answers.Q2}
5. Project Vision ${answers.Q3}
6. Your Goals and Dreams ${answers.Q4}
7. Understanding Your Audience ${answers.Q5}
8. Core Features and Must-Haves ${answers.Q6}
9. Platform Preferences ${answers.Q7}
10. Design and User Experience ${answers.Q8}
11. Timing and Milestones ${answers.Q9}
12. Budget Considerations ${answers.Q10}
13. Any Other Thoughts? ${answers.Q11}
`;

        const payload = {
            conversations: [
                {
                    role: "user",
                    content: content,
                }
            ],
            chatSession: chatSession,
        };

        setIsPopupVisible(true); // Show the popup

        try {
            const response = await fetch('https://dashboard.knightingale.ai/ai/run/31c3f31b-ff72-4199-86a4-be39d3290242', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                setAnswers({
                    Q1: '',
                    Q2: '',
                    Q3: '',
                    Q4: '',
                    Q5: '',
                    Q6: '',
                    Q7: '',
                    Q8: '',
                    Q9: '',
                    Q10: '',
                    Q11: '',
                    Q12: '',
                    Q13: '',
                });

                setIsSubmitted(true); // Show the submitted message

            } else {
                console.error('Error submitting the form:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            // setIsPopupVisible(false); // Hide the popup
        }

        console.log('Form submitted successfully');
    };

    const handleClose = () => {
        setIsSubmitted(true);
        window.location.href = '/'
    };


    return (

        <div style={styles.container} className='container'>
            <div className='container-wrapper'>
            <video autoPlay loop muted className="est-background-video desktop_background">
        <source src={aspi_desktop_idle} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <video autoPlay loop muted className="est-background-video mobile_background">
        <source src={aspi_mobile_idle} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
            </div>
            <form onSubmit={handleSubmit} className='estimationform'>
                {/* Form fields */}
                <div className='estimationform-header'>
                    <h3>Estimation Form</h3>
                </div>
                <div className="items-wrapper input-wrapper">
                    <div className="input-inner questionContainer" >
                        <label htmlFor="Q12">1. Name *</label>
                        <input
                        className='est-input'
                            type="text"
                            id="Q12"
                            name="Q12"
                            value={answers.Q12}
                            onChange={handleInputChange}
                            placeholder="Enter your name"
                            required
                        />
                    </div>
                    <div className="input-inner"  >
                        <label htmlFor="Q13">2. Company name *</label>
                        <input
                        className='est-input'
                            type="text"
                            id="Q13"
                            name="Q13"
                            value={answers.Q13}
                            onChange={handleInputChange}
                            placeholder="Enter your company name"
                            required
                        />
                    </div>
                </div>
                <div className='items-wrapper input-wrapper'>
                    <div className='input-inner questionContainer'  >
                        <label htmlFor="Q1">3. Email *</label>
                        <input
                        className='est-input'
                            type="email"
                            id="Q1"
                            name="Q1"
                            value={answers.Q1}
                            onChange={handleInputChange}
                            placeholder="Enter your email"
                            required
                        />
                    </div>
                    <div className="input-inner questionContainer" >
                        <label htmlFor="Q2">4. Phone Number *</label>
                        <input
                        className='est-input'
                            type="tel"
                            id="Q2"
                            name="Q2"
                            value={answers.Q2}
                            onChange={handleInputChange}
                            placeholder="Enter your phone number"
                            required
                        />
                    </div>
                </div>
                <div className='items-wrapper text-area-wrapper'>
                    <div className='input-area questionContainer'  >
                        <label htmlFor="Q3">5. Project Vision</label>
                        <textarea
                        className='textarea-wrapper'
                            id="Q3"
                            name="Q3"
                            value={answers.Q3}
                            onChange={handleInputChange}
                            placeholder="Enter your estimate"
                        />
                    </div>
                    <div className='input-area questionContainer'  >
                        <label htmlFor="Q4">6. Your Goals and Dreams</label>
                        <textarea
                        className='textarea-wrapper'
                            id="Q4"
                            name="Q4"
                            value={answers.Q4}
                            onChange={handleInputChange}
                            placeholder="Enter your estimate"
                        />
                    </div>
                    <div className='input-area questionContainer'  >
                        <label htmlFor="Q5">7. Understanding Your Audience</label>
                        <textarea
                        className='textarea-wrapper'
                            id="Q5"
                            name="Q5"
                            value={answers.Q5}
                            onChange={handleInputChange}
                            placeholder="Enter your estimate"
                        />
                    </div>
                    <div className='input-area questionContainer'  >
                        <label htmlFor="Q6">8. Core Features and Must-Haves</label>
                        <textarea
                        className='textarea-wrapper'
                            id="Q6"
                            name="Q6"
                            value={answers.Q6}
                            onChange={handleInputChange}
                            placeholder="Enter your estimate"
                        />
                    </div>
                    <div className='input-area questionContainer'  >
                        <label htmlFor="Q7">9. Platform Preferences</label>
                        <textarea
                        className='textarea-wrapper'
                            id="Q7"
                            name="Q7"
                            value={answers.Q7}
                            onChange={handleInputChange}
                            placeholder="Enter your estimate"
                        />
                    </div>
                    <div className='input-area questionContainer'  >
                        <label htmlFor="Q8">10. Design and User Experience</label>
                        <textarea
                        className='textarea-wrapper'
                            id="Q8"
                            name="Q8"
                            value={answers.Q8}
                            onChange={handleInputChange}
                            placeholder="Enter your estimate"
                        />
                    </div>
                    <div className='input-area questionContainer'  >
                        <label htmlFor="Q9">11. Timing and Milestones</label>
                        <textarea
                        className='textarea-wrapper'
                            id="Q9"
                            name="Q9"
                            value={answers.Q9}
                            onChange={handleInputChange}
                            placeholder="Enter your estimate"
                        />
                    </div>
                    <div className='input-area questionContainer'  >
                        <label htmlFor="Q10">12. Budget Considerations</label>
                        <textarea
                        className='textarea-wrapper'
                            id="Q10"
                            name="Q10"
                            value={answers.Q10}
                            onChange={handleInputChange}
                            placeholder="Enter your estimate"
                        />
                    </div>
                    <div className='input-area questionContainer'  >
                        <label htmlFor="Q11">13. Any Other Thoughts?</label>
                        <textarea
                        className='textarea-wrapper'
                            id="Q11"
                            name="Q11"
                            value={answers.Q11}
                            onChange={handleInputChange}
                            placeholder="Enter your estimate"
                        />
                    </div>
                </div>




                <button type="submit" className='submitButton' >Submit</button>
            </form>

            {isPopupVisible && (
                <div className='overlay'>
                    {isSubmitted ? (
                        <div className='successMessage'>
                            <div className='successText'>Thank you! Your form has been submitted successfully.</div>
                            <button className='okButton' onClick={handleClose}>OK</button>
                        </div>
                    ) : (
                        <div className='popupContent'>
                            <div className='circle-loader'></div>
                            <div className='popupText'>Submitting...</div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

// Inline styles for the form, popup, and success message
const styles = {

};

export default EstimationForm;
