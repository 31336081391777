import React, { useEffect, useState } from 'react';
// import './PersonalityTest.css';
import rating1 from "../../img/rating/rating1.png";
import rating2 from "../../img/rating/rating2.png";
import rating3 from "../../img/rating/rating3.png";
import rating4 from "../../img/rating/rating4.png";
import rating5 from "../../img/rating/rating5.png";
import changemangement from "../../img/pick/change-management.png";
import choice from "../../img/pick/choice.png";
import choice1 from "../../img/pick/choice1.png";
import datasience from "../../img/pick/data-science.png";
import friends from "../../img/pick/friends.png";
import knowledge from "../../img/pick/knowledge.png";
import loneliness from "../../img/pick/loneliness.png";
import practice from "../../img/pick/practice.png";
import sketch from "../../img/pick/sketch.png";
import teamwork from "../../img/pick/teamwork.png";
import edulogo from "../../img/pick/edu_advisor_logo.png";
import selection_1_graphic from "../../img/pick/selection_1_graphic.png";
import selection_2_graphic from "../../img/pick/selection_2_graphic.png";
import selection_3_graphic from "../../img/pick/selection_3_graphic.png";
import selection_4_graphic from "../../img/pick/selection_4_graphic.png";
import selection_5_graphic from "../../img/pick/selection_5_graphic.png";
import selection_6_graphic from "../../img/pick/selection_6_graphic.png";
import selection_7_graphic from "../../img/pick/selection_7_graphic.png";
import selection_8_graphic from "../../img/pick/selection_8_graphic.png";
import done_icon from "../../img/pick/done_icon.svg";

import ReactDOMServer from 'react-dom/server';
import genaratevideo from '../../videos/chat/genarating_animation_loop.mp4'

const PersonalityTest = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const chatSession = localStorage.getItem("chatSession");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [reportData, setReportData] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [answers, setAnswers] = useState({
    name: '',
    quickPick: '',
    learningStyle: '',
    decisionMaking: '',
    lifeApproach: '',
    ratings: {
      statement1: '',
      statement2: '',
      statement3: '',
      statement4: '',
      statement5: '',
      statement6: '',
      statement7: '',
      statement8: '',
    },
    sports: [],
    traits: [],
    traits2: [], // Add traits to the answers state
    personalityDescription: '',
    personalStrenth: '',
    developarealStrenth: '',
    feelMostEnlightened: '',
  });
  const [completedSteps, setCompletedSteps] = useState(0);

  // Load answers from local storage when the component mounts
  useEffect(() => {
    const savedAnswers = JSON.parse(localStorage.getItem('answers'));
    if (savedAnswers) {
      setAnswers(savedAnswers);
    }
  }, []);

  // Save answers to local storage whenever they change
  useEffect(() => {
    localStorage.setItem('answers', JSON.stringify(answers));
  }, [answers]);

  // Count completed steps based on the answers
  const countCompletedSteps = () => {
    let count = 0;
    if (answers.name) count++;
    if (answers.quickPick) count++;
    if (answers.learningStyle) count++;
    if (answers.decisionMaking) count++;
    if (answers.lifeApproach) count++;
    if (answers.ratings.statement1) count++;
    if (answers.ratings.statement2) count++;
    if (answers.ratings.statement3) count++;
    if (answers.ratings.statement4) count++;
    if (answers.ratings.statement5) count++;
    if (answers.ratings.statement6) count++;
    if (answers.ratings.statement7) count++;
    if (answers.ratings.statement8) count++;
    if (answers.sports.length > 0) count++;
    if (answers.traits.length > 0) count++; // Include traits in count
    if (answers.traits2.length > 0) count++;
    if (answers.personalityDescription) count++;
    if (answers.personalStrenth) count++;
    if (answers.developarealStrenth) count++;
    if (answers.feelMostEnlightened) count++;


    setCompletedSteps(count);
  };

  // Handler for moving to the next step
  const handleNext = () => {
    const showError = (message) => {
      const errorElement = document.createElement('div');
      errorElement.className = 'error-message';
      errorElement.textContent = message;
      document.body.appendChild(errorElement);
      setTimeout(() => {
        document.body.removeChild(errorElement);
      }, 3000); // Remove the message after 3 seconds
    };

    if (currentStep === 1 && !answers.name) {
      showError("Please enter your name to proceed.");
      return;
    }
    if (currentStep === 2 && !answers.quickPick) {
      showError("Please choose an option to proceed.");
      return;
    }
    if (currentStep === 3 && !answers.learningStyle) {
      showError("Please select how you prefer to learn to proceed.");
      return;
    }
    if (currentStep === 4 && !answers.decisionMaking) {
      showError("Please select how you make important decisions to proceed.");
      return;
    }
    if (currentStep === 5 && !answers.lifeApproach) {
      showError("Please select your general approach to life to proceed.");
      return;
    }
    if (currentStep >= 6 && currentStep <= 13) {
      const statementIndex = currentStep - 5; // Map currentStep to statement number
      if (!answers.ratings[`statement${statementIndex}`]) {
        showError("Please rate the statement to proceed.");
        return;
      }
    }

    if (currentStep === 14 && answers.traits.length === 0) {
      showError("Please select at least one trait to proceed.");
      return;
    }

    if (currentStep === 15 && answers.traits2.length === 0) {
      showError("Please select at least one trait to proceed.");
      return;
    }

    if (currentStep === 16 && !answers.personalityDescription) {
      showError("Please enter your personality description to proceed.");
      return;
    }

    if (currentStep === 17 && !answers.personalStrenth) {
      showError("Please enter your personal strength to proceed.");
      return;
    }

    if (currentStep === 18 && !answers.developarealStrenth) {
      showError("Please enter your developmental strength to proceed.");
      return;
    }

    if (currentStep === 19 && !answers.feelMostEnlightened) {
      showError("Please enter what makes you feel most enlightened to proceed.");
      return;
    }

    setCurrentStep(currentStep + 1);
    countCompletedSteps();
  };

  // Handler for moving to the previous step
  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
    countCompletedSteps(); // Ensure progress bar updates when going back
  };

  // Handler for updating text inputs
  const handleInputChange = (step, value) => {
    setAnswers(prevAnswers => {
      const newAnswers = {
        ...prevAnswers,
        [step]: value,
      };
      countCompletedSteps(); // Update progress bar on change
      return newAnswers;
    });
  };

  // Handler for updating radio inputs
  const handleRadioChange = (step, value) => {
    // Update the state with the new value and also update the progress bar
    setAnswers(prevAnswers => {
      const updatedAnswers = {
        ...prevAnswers,
        [step]: value,
      };
      countCompletedSteps(); // Update progress bar on change
      return updatedAnswers;
    });

    // Manage label classes
    const labels = document.querySelectorAll('.radio-label');
    labels.forEach(label => label.classList.remove('checked'));
    const checkedLabel = document.querySelector(`input[name="${step}"][value="${value}"]`).closest('.radio-label');
    if (checkedLabel) {
      checkedLabel.classList.add('checked');
    }
  };


  // Handler for updating checkbox inputs
  const handleCheckboxChange = (trait) => {
    setAnswers(prevAnswers => {
      const newTraits = prevAnswers.traits.includes(trait)
        ? prevAnswers.traits.filter(item => item !== trait)
        : [...prevAnswers.traits, trait];
      const newAnswers = {
        ...prevAnswers,
        traits: newTraits,
      };
      countCompletedSteps(); // Update progress bar on change
      return newAnswers;
    });
  };

  const handleCheckboxChange2 = (trait2) => {
    setAnswers(prevAnswers2 => {
      const newTraits2 = prevAnswers2.traits2.includes(trait2)
        ? prevAnswers2.traits2.filter(item => item !== trait2)
        : [...prevAnswers2.traits2, trait2];
      const newAnswers2 = {
        ...prevAnswers2,
        traits2: newTraits2,
      };
      countCompletedSteps(); // Update progress bar on change
      return newAnswers2;
    });
  };
  // Handler for updating rating inputs
  const handleRatingChange = (statement, value) => {
    setAnswers(prevAnswers => {
      const newAnswers = {
        ...prevAnswers,
        ratings: { ...prevAnswers.ratings, [statement]: value },
      };
      countCompletedSteps(); // Update progress bar on change
      return newAnswers;
    });
  };

  // Handler for finishing the wizard
  const handleFinish = async (event) => {
    event.preventDefault();
    // Prepare questions and answers
    const ratingScale = {
      1: "Strongly Disagree",
      2: "Disagree",
      3: "Neutral",
      4: "Agree",
      5: "Strongly Agree"
    };

    // Prepare questions and answers
    const content = `
  1. What's your name? ${answers.name}
  2. How do you feel most refreshed and energized? ${answers.quickPick}
  3. How do you prefer to learn and understand new things? ${answers.learningStyle}
  4. How do you make important decisions? ${answers.decisionMaking}
  5. What is your general approach to life? ${answers.lifeApproach}
  6. I love coming up with new ideas and imagining what could be. ${ratingScale[answers.ratings.statement1]}
  7. I enjoy doing things with my hands and seeing tangible results. ${ratingScale[answers.ratings.statement2]}
  8. I like to think about things from different angles before making a decision. ${ratingScale[answers.ratings.statement3]}
  9. I care deeply about how other people feel. ${ratingScale[answers.ratings.statement4]}
  10. I get excited by busy and ever-changing situations. ${ratingScale[answers.ratings.statement5]}
  11. I like having a familiar routine and knowing what to expect. ${ratingScale[answers.ratings.statement6]}
  12. I like having lively discussions and debates about different ideas. ${ratingScale[answers.ratings.statement7]}
  13. I'm comfortable sharing my feelings and connecting with others emotionally. ${ratingScale[answers.ratings.statement8]}
  14. Words that describe me: ${answers.traits.join(', ')}
  15. In a group, I tend to: ${answers.traits2.join(', ')}
  16. Describe your personality: ${answers.personalityDescription}
  17. What is your personal strength? ${answers.personalStrenth}
  18. What is your developmental strength? ${answers.developarealStrenth}
  19. What makes you feel most enlightened? ${answers.feelMostEnlightened}
  `;

    const payload = {
      conversations: [
        {
          role: "user",
          content: content,
        }
      ],
      chatSession: chatSession,
    };

    setIsPopupVisible(true);


    try {
      const response = await fetch('https://dashboard.knightingale.ai/ai/run/7ac54f14-d1b8-4529-bf25-58c77bf5435e', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      console.log('ss' + response.completion)
      if (response.ok) {

        const data = await response.json(); // Parse the JSON from the response
        console.log(data.completion);
        setReportData(data.completion);

        // setAnswers({
        //   name: '',
        //   quickPick: '',
        //   learningStyle: '',
        //   decisionMaking: '',
        //   lifeApproach: '',
        //   ratings: {
        //     statement1: '',
        //     statement2: '',
        //     statement3: '',
        //     statement4: '',
        //     statement5: '',
        //     statement6: '',
        //     statement7: '',
        //     statement8: '',
        //   },
        //   sports: [],
        //   traits: [],
        //   traits2: [],
        //   personalityDescription: '',
        //   personalStrenth: '',
        //   developarealStrenth: '',
        //   feelMostEnlightened: '',
        // });

        setIsSubmitted(true); // Show the submitted message

      } else {
        console.error('Error submitting the form:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      // setIsPopupVisible(false); // Hide the popup
    }


    console.log(content);

  };


  // Render progress bar
  const renderProgressBar = () => (
    <div className="progress-bar-container">
      <progress value={completedSteps} max="19" className="progress-bar" />
      <p className="progress-text">

      </p>
    </div>
  );


  // Render rating step with buttons
  const renderRatingStep = (statement, step) => {
    // Array of images corresponding to each rating
    const ratingImages = [rating5, rating4, rating3, rating2, rating1];
    // Array of text labels corresponding to each rating
    const ratingTexts = ["Strongly Disagree", "Disagree", "Neutral", "Agree", "Strongly Agree"]; // Adjust as needed
    const ratingNumber = ["1", "2", "3", "4", "5"];
    const ratingbBckground = ["strong-disagree", "disagree", "neutral", "agree", "strongly-agree"];
    return (
      <div className="wizard-section">
        <div>
          <h3 className="section-title">Step {step}: Rate the Statement</h3>
          <p className="question-text">Rate the following statement from 1 to 5:</p>
          <p className="statement-text">{statement}</p>
        </div>
        <div className="main-container-wrapper">
          <div className="rating-buttons">
            {[1, 2, 3, 4, 5].map((rating, index) => (
              <button
                key={rating}
                className={`rating-button ${answers.ratings[`statement${step}`] === rating ? 'selected' : ''}`}
                onClick={() => handleRatingChange(`statement${step}`, rating)}
              >
                {/* <img src={ratingImages[index]} alt={`Rating ${rating}`} /> */}
                <div className={`suggetion-number  ` + ratingbBckground[index]}>{ratingNumber[index]}</div>
                <span className="rating-text">{ratingTexts[index]}</span>
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  };





  // Render traits step
  const renderTraitsStep = () => (
    <div className="wizard-section">
      <div>
        <h3 className="section-title">Part 3: Pick Your Traits</h3>
        <p className="statement-text">Choose all that apply to you:</p>
      </div>
      <div className="main-container-wrapper">
        <div className="checkbox-group">
          {[
            "Analytical",
            "Creative",
            "Empathetic",
            "Organized",
            "Spontaneous",
            "Practical",
            "Visionary",
            "Adaptable",
          ].map(trait => (
            <label key={trait} className={`traits-label ${answers.traits.includes(trait) ? 'selected-checked' : ''}`}>
              <input
                type="checkbox"
                checked={answers.traits.includes(trait)}
                onChange={() => handleCheckboxChange(trait)}
              />
              <span className='custom-checkbox'></span>
              <span className='traits1-text'>{trait}</span>
            </label>

          ))}
        </div>


      </div>
    </div>
  );

  const renderTraitsStep2 = () => (
    <div className="wizard-section">
      <div>
        <h3 className="section-title">Part 3: Pick Your Traits</h3>
        <p className="statement-text">In a group, I tend to:</p>
      </div>

      <div className="main-container-wrapper">
        <div className="checkbox-group">
          {[
            "Take the lead and get things started.",
            "Offer support and encouragement to others",
            "Share logical and objective viewpoints.",
            "Come up with new and creative ideas.",
            "Make sure everyone feels included and understood.",
            "Focus on getting things done and paying attention to details.",
          ].map(trait2 => (
            <label key={trait2} className={`traits-label traits-label2 ${answers.traits2.includes(trait2) ? 'selected-checked' : ''}`}>
              <input
                type="checkbox"
                checked={answers.traits2.includes(trait2)}
                onChange={() => handleCheckboxChange2(trait2)}
              />
              <span className='custom-checkbox'></span>
              <span className='traits-text'>{trait2}</span>
            </label>
          ))}
        </div>

      </div>
    </div>
  );



  const handleClose = () => {
    setIsSubmitted(true);
    window.location.href = '/'
  };

  // const handlePrint = () => {
  //   window.print();
  // };


  const handlePrint = () => {
    const printWindow = window.open('', '', 'height=600,width=800');

    // Ensure the document structure is properly formatted
    printWindow.document.write(`<html><head><title>Print</title>
       <style>
          body {
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            margin: 0;
            padding: 20px;
          }
          .report-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
          }
          .report-left-side {
            text-align: left;
          }
          .report-right-side {
            text-align: right;
          }
          .report-logo {
            height: 50px;
          }
          .responseText {
            overflow-x: auto;
            white-space: pre-wrap;
            font-weight: normal;
            font-family: 'Roboto, sans-serif';
            font-size: 20px;
          }
          footer {
            margin-top: 20px;
            border-top: 1px solid #ddd;
            padding-top: 10px;
            text-align: center;
            font-size: 12px;
            color: #777;
          }
        </style>
      </head><body>`);
    printWindow.document.write('<div>');
    printWindow.document.write(`
      <div style="text-align: center; display: flex; align-items: center; justify-content: center;">
      </div>
    `);
    printWindow.document.write(`${renderResponseToHtml()}`);  // Ensure renderResponse() returns a valid HTML snippet
    printWindow.document.write(`
      <footer style="margin-top: 20px; border-top: 1px solid #ddd; padding-top: 10px;">
      </footer>
    `);
    printWindow.document.write('</div>');
    printWindow.document.write('</body></html>');

    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };


  const renderResponseToHtml = () => {
    return ReactDOMServer.renderToStaticMarkup(renderResponse());
  };

  const renderResponse = () => {
    if (reportData) {
      // Replace double asterisks with <strong> tags for bold text
      const formattedReportData = reportData
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')  // Replace double asterisks with <strong> tags
        .replace(/##(.*?)/g, '<strong>$1</strong>');  // Replace text between double hash with <strong> tags


      const getCurrentDate = () => {
        const today = new Date();
        return today.toLocaleDateString(); // Format as needed, e.g., "MM/DD/YYYY"
      };
      return (
        <div>
          {/* Header Section */}
          <header className='report-header'>
            <div className="report-left-side">
              <h1>Personality Test</h1>
              <p>Test Date : {getCurrentDate()} </p>
              <p>Test ID: {chatSession}</p>
            </div>
            <div className="report-right-side">
              <img src={edulogo} alt="Logo" className='report-logo' />
            </div>
          </header>

          {/* Main Content */}
          <pre
            className="responseText"
            style={{ overflowX: 'auto', whiteSpace: 'pre-wrap', fontWeight: 'normal', fontFamily: 'Roboto, sans-serif', fontSize: '20px' }}
            dangerouslySetInnerHTML={{ __html: formattedReportData }}
          ></pre>
        </div>
      );
    }
    return null;
  };


  return (
    <div className="wizard-container">
      <div className='wizard-header-wrapper'>
        <div className='wizard-header-wrapper'>
          <div className='wizard-header-wrapper'>

          </div>
          <div className='wizard-header-wrapper'>

          </div>
        </div>
      </div>
      <p className="progress-text">
      {currentStep < 20 &&   <><span> {completedSteps} of 19 </span>questions answered</> }

      </p>
      {currentStep === 1 && (
        <div className="wizard-section">
          <div>
            <h3 className="section-title">Let's Discover Your Unique Personality!</h3>
            <p className="greeting-text">First things first, what's your name?</p>
          </div>
          <div className='personal-description'>

            <input
              type="text"
              placeholder="Enter your first name"
              value={answers.name}
              onChange={(e) => handleInputChange('name', e.target.value)}
              className="input-field"
            />
          </div>
          <div></div>
          {answers.name && (
            <p className="greeting-text2">
              <span><strong>Hi {answers.name}</strong>,</span> excited to embark on this personality journey with you! Let's get started.
            </p>
          )}

        </div>
      )}

      {currentStep === 2 && (
        <div className="wizard-section">
          <div>
            <h3 className="section-title">Part 1: Quick Picks</h3>
            <p className="question-text">Choose the option that best describes you:</p>
            <p className="question-text question-text2">How do you feel most refreshed and energized?</p>
          </div>
          <div className="main-container-wrapper">
            <div className="radio-group part1-radio-group">
              <label className={`radio-label ${answers.quickPick === "Spending time with friends and being out and about." ? 'checked' : ''}`}>
                <input
                  type="radio"
                  name="quickPick"
                  value="Spending time with friends and being out and about."
                  checked={answers.quickPick === "Spending time with friends and being out and about."}
                  onChange={(e) => handleRadioChange('quickPick', e.target.value)}
                />
                <img
                  src={selection_1_graphic}
                  alt="Spending time with friends and being out and about"
                  onClick={() => handleRadioChange('quickPick', 'Spending time with friends and being out and about.')}
                  className="radio-image"
                />
                <p className='part1text'>Spending time with friends and being out and about.</p>
              </label>
              <label className={`radio-label ${answers.quickPick === "Having quiet time alone or deep conversations with a few close friends." ? 'checked' : ''}`}>
                <input
                  type="radio"
                  name="quickPick"
                  value="Having quiet time alone or deep conversations with a few close friends."
                  checked={answers.quickPick === "Having quiet time alone or deep conversations with a few close friends."}
                  onChange={(e) => handleRadioChange('quickPick', e.target.value)}
                />
                <img
                  src={selection_2_graphic}
                  alt="Having quiet time alone or deep conversations with a few close friends."
                  onClick={() => handleRadioChange('quickPick', 'Having quiet time alone or deep conversations with a few close friends.')}
                  className="radio-image"
                />
                <p className='part1text'>Having quiet time alone or deep conversations with a few close friends.</p>
              </label>
            </div>
          </div>

        </div>
      )}

      {currentStep === 3 && (
        <div className="wizard-section">
          <div>
            <h3 className="section-title">Part 1: Quick Picks</h3>
            <p className="question-text">Choose the option that best describes you:</p>
            <p className="question-text question-text2">How do you prefer to learn and understand new things?</p>
          </div>
          <div className="main-container-wrapper">
            <div className="radio-group part1-radio-group">
              <label className={`radio-label ${answers.learningStyle === "Focusing on practical details and real-world examples." ? 'checked' : ''}`}>
                <input
                  type="radio"
                  name="learningStyle"
                  value="Focusing on practical details and real-world examples."
                  checked={answers.learningStyle === "Focusing on practical details and real-world examples."}
                  onChange={(e) => handleRadioChange('learningStyle', e.target.value)}
                />
                <img
                  src={selection_3_graphic}
                  alt="Focusing on practical details and real-world examples"
                  onClick={() => handleRadioChange('learningStyle', 'Focusing on practical details and real-world examples.')}
                  className="radio-image"
                />
                <p className='part1text'>Focusing on practical details and real-world examples.</p>
              </label>
              <label className={`radio-label ${answers.learningStyle === "Exploring big ideas, possibilities, and connecting the dots." ? 'checked' : ''}`}>
                <input
                  type="radio"
                  name="learningStyle"
                  value="Exploring big ideas, possibilities, and connecting the dots."
                  checked={answers.learningStyle === "Exploring big ideas, possibilities, and connecting the dots."}
                  onChange={(e) => handleRadioChange('learningStyle', e.target.value)}
                />
                <img
                  src={selection_4_graphic}
                  alt="Exploring big ideas, possibilities, and connecting the dots"
                  onClick={() => handleRadioChange('learningStyle', 'Exploring big ideas, possibilities, and connecting the dots.')}
                  className="radio-image"
                />
                <p className='part1text'>Exploring big ideas, possibilities, and connecting the dots.</p>
              </label>
            </div>
          </div>
        </div>
      )}

      {currentStep === 4 && (
        <div className="wizard-section">
          <div>
            <h3 className="section-title">Part 1: Quick Picks</h3>
            <p className="question-text">Choose the option that best describes you:</p>
            <p className="question-text question-text2">How do you make important decisions?</p>
          </div>
          <div className="main-container-wrapper">
            <div className="radio-group part1-radio-group">
              <label className={`radio-label ${answers.decisionMaking === "Using logic, facts, and careful analysis." ? 'checked' : ''}`}>
                <input
                  type="radio"
                  name="decisionMaking"
                  value="Using logic, facts, and careful analysis."
                  checked={answers.decisionMaking === "Using logic, facts, and careful analysis."}
                  onChange={(e) => handleRadioChange('decisionMaking', e.target.value)}
                />
                <img
                  src={selection_5_graphic}
                  alt="Using logic, facts, and careful analysis"
                  onClick={() => handleRadioChange('decisionMaking', 'Using logic, facts, and careful analysis.')}
                  className="radio-image"
                />
                <p className='part1text'>Using logic, facts, and careful analysis.</p>
              </label>
              <label className={`radio-label ${answers.decisionMaking === "Considering my values, feelings, and how my choices impact others." ? 'checked' : ''}`}>
                <input
                  type="radio"
                  name="decisionMaking"
                  value="Considering my values, feelings, and how my choices impact others."
                  checked={answers.decisionMaking === "Considering my values, feelings, and how my choices impact others."}
                  onChange={(e) => handleRadioChange('decisionMaking', e.target.value)}
                />
                <img
                  src={selection_6_graphic}
                  alt="Considering my values, feelings, and how my choices impact others"
                  onClick={() => handleRadioChange('decisionMaking', 'Considering my values, feelings, and how my choices impact others.')}
                  className="radio-image"
                />
                <p className='part1text'>Considering my values, feelings, and how my choices impact others.</p>
              </label>
            </div>
          </div>
        </div>
      )}

      {currentStep === 5 && (
        <div className="wizard-section">
          <div>

            <h3 className="section-title">Part 1: Quick Picks</h3>
            <p className="question-text">Choose the option that best describes you:</p>
            <p className="question-text question-text2">What's your general approach to life and everyday tasks?</p>
          </div>
          <div className="main-container-wrapper">
            <div className="radio-group part1-radio-group">
              <label className={`radio-label ${answers.lifeApproach === "I like having a clear plan and sticking to a schedule." ? 'checked' : ''}`}>
                <input
                  type="radio"
                  name="lifeApproach"
                  value="I like having a clear plan and sticking to a schedule."
                  checked={answers.lifeApproach === "I like having a clear plan and sticking to a schedule."}
                  onChange={(e) => handleRadioChange('lifeApproach', e.target.value)}
                />
                <img
                  src={selection_7_graphic}
                  alt="I like having a clear plan and sticking to a schedule"
                  onClick={() => handleRadioChange('lifeApproach', 'I like having a clear plan and sticking to a schedule.')}
                  className="radio-image"
                />
                <p className='part1text'>I like having a clear plan and sticking to a schedule.</p>
              </label>
              <label className={`radio-label ${answers.lifeApproach === "I prefer going with the flow and being open to changes." ? 'checked' : ''}`}>
                <input
                  type="radio"
                  name="lifeApproach"
                  value="I prefer going with the flow and being open to changes."
                  checked={answers.lifeApproach === "I prefer going with the flow and being open to changes."}
                  onChange={(e) => handleRadioChange('lifeApproach', e.target.value)}
                />
                <img
                  src={selection_8_graphic}
                  alt="I prefer going with the flow and being open to changes"
                  onClick={() => handleRadioChange('lifeApproach', 'I prefer going with the flow and being open to changes.')}
                  className="radio-image"
                />
                <p className='part1text'>I prefer going with the flow and being open to changes.</p>
              </label>
            </div>
          </div>
        </div>
      )}


      {currentStep === 6 && renderRatingStep("I love coming up with new ideas and imagining what could be.", 1)}

      {currentStep === 7 && renderRatingStep("I enjoy doing things with my hands and seeing tangible results.", 2)}

      {currentStep === 8 && renderRatingStep("I like to think about things from different angles before making a decision.", 3)}

      {currentStep === 9 && renderRatingStep("I care deeply about how other people feel.", 4)}

      {currentStep === 10 && renderRatingStep("I get excited by busy and ever-changing situations.", 5)}

      {currentStep === 11 && renderRatingStep("I like having a familiar routine and knowing what to expect.", 6)}

      {currentStep === 12 && renderRatingStep("I like having lively discussions and debates about different ideas.", 7)}

      {currentStep === 13 && renderRatingStep("I'm comfortable sharing my feelings and connecting with others emotionally.", 8)}

      {currentStep === 14 && renderTraitsStep()}

      {currentStep === 15 && renderTraitsStep2()}

      {currentStep === 16 && (
        <div className="wizard-section">
          <h3 className="section-title">Just for You, {answers.name}</h3>
          <p className="statement-text">In 3-5 words, describe your personality:</p>

          <div className='personal-description'>

            <textarea
              type="text"
              name="personalityDescription"
              value={answers.personalityDescription || ''}
              onChange={(e) => handleInputChange('personalityDescription', e.target.value)}
              className="text-input"
              placeholder="Enter 3-5 words, describe your personality"
            />
          </div>
          <div></div>

        </div>
      )}

      {currentStep === 17 && (
        <div className="wizard-section">
          <h3 className="section-title">Just for You, {answers.name}</h3>
          <p className="statement-text">Your top 2-3 strengths:</p>

          <div className='personal-description'>
            <textarea
              type="text"
              name="personalStrenth"
              value={answers.personalStrenth || ''}
              onChange={(e) => handleInputChange('personalStrenth', e.target.value)}
              className="text-input"
              placeholder="Your top 2-3 strengths"
            />
          </div>
          <div></div>

        </div>
      )}

      {currentStep === 18 && (
        <div className="wizard-section">
          <h3 className="section-title">Just for You, {answers.name}</h3>
          <p className="statement-text">1-2 areas you'd like to develop</p>
          <div className='personal-description'>
            <textarea
              type="text"
              name="developarealStrenth"
              value={answers.developarealStrenth || ''}
              onChange={(e) => handleInputChange('developarealStrenth', e.target.value)}
              className="text-input"
              placeholder="1-2 areas you'd like to develop"
            />
          </div>
          <div></div>

        </div>
      )}
      {currentStep === 19 && (
        <div className="wizard-section">
          <h3 className="section-title">Just for You, {answers.name}</h3>
          <p className="statement-text">In one sentence, what makes you feel most alive and fulfilled?</p>

          <div className='personal-description'>

            <textarea
              type="text"
              name="feelMostEnlightened"
              value={answers.feelMostEnlightened || ''}
              onChange={(e) => handleInputChange('feelMostEnlightened', e.target.value)}
              className="text-input"
              placeholder="In one sentence, what makes you feel most alive and fulfilled?"
            />
          </div>
          <div></div>
        </div>
      )}





      <div className="button-group">
        {currentStep < 20 && renderProgressBar()}

        <div className='prev-next-container'>

          {currentStep > 1 && currentStep < 20 && (<button onClick={handlePrevious} className="button previous-button">Previous</button>)}
          {currentStep < 20 && (<button onClick={handleNext} className="button next-button">Next</button>)}
        </div>
        {currentStep === 20 && (
          <div className="wizard-section">
            <div className="wizard-section-container">
<img className='finish-icon' src={done_icon} alt='done icon'/>
              <h3 className="finish-section-title"> Thank you for submitting, {answers.name}</h3>
              <p className="completion-text"> Can't wait to reveal your unique personality profile!</p>
              <button onClick={handleFinish} className="button finish-button">View Report</button>
            </div>
          </div>
        )}
      </div>
      {isPopupVisible && (
        <div className='overlay'>
          {isSubmitted ? (
            <div className='successMessage'>

              <div className='successText'>Thank you! Your form has been submitted successfully.</div>
              <div className='report-container-print'>

                {renderResponse()}
              </div>
              <div className='printButtons'>
                <button className='okButton' onClick={handleClose}>Home</button>
                <button className='okButton' onClick={handlePrint}>Print Report</button>
              </div>

            </div>
          ) : (
            <div className='popupContent'>
              {/* <div className='circle-loader'></div> */}
              <div>
                <video
                  className={`genarateVideo`}
                  style={{

                  }}
                  playsInline
                  autoPlay
                  loop
                  muted
                  src={genaratevideo}
                />
              </div>
              <div className='popupTitle'>Generating Report...</div>
              <div className='popupText'>Your Report is being Genarated. <br />Please wait...</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PersonalityTest;






















